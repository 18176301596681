.nav-bar-client {
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  margin: 0;
  background-color: rgba(50, 82, 94, 0.294);
  border-radius: 16px;
}

.nav-bar-client .nav-item {
  text-decoration: none;
}

.nav-bar-client .nav-link {
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 4px;
  padding: 4px 10px;
  color: #d5fe5b;
  border-radius: 16px;
  transition: all 0.3s;
}

.nav-bar-client .nav-link.collapsed {
  color: rgb(219, 226, 232);
  background: none;
}

.nav-bar-client .nav-link:hover {
  color: #eaf0f1;
  background-color: #4fb3ff6c;
}