.login-header-client {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    padding: 7px 15px;
  }

.main-client {
    display: flex;
    flex-direction: column;
    margin-top: 90px;
}

.top-bar-client {
    display: flex;
    flex-direction: row;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    padding: 0px 16px;
    left: 0;
    top: 0;
    right: 0;
    background-color: #11254e;
    list-style: none;
    z-index: 900;
    box-shadow: 3px 3px 10px #919191;
  }