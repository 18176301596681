.bold { font-weight: bold !important; }

.medium { font-weight: 600 !important; }

.medium-x { font-weight: 500 !important; }

.regular { font-weight: 200 !important; }

.small { font-size: 11px !important; }

.small-x { font-size: 10px !important; }

.font-12 { font-size: 12px !important; }
.font-13 { font-size: 13px !important; }
.font-14 { font-size: 14px !important; }
.font-16 { font-size: 16px !important; }
.font-18 { font-size: 18px !important; }

.font-20 { font-size: 20px !important; }
.font-25 { font-size: 25px !important; }
.font-30 { font-size: 30px !important; }
.font-40 { font-size: 40px !important; }
.font-50 { font-size: 50px !important; }
.font-60 { font-size: 60px !important; }
.font-70 { font-size: 70px !important; }
.font-80 { font-size: 80px !important; }
