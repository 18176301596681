.app .menu {
    background-color: rgb(20, 54, 104) !important;
    border: 1px solid #37a3d1 !important;
}

.app .menu .option {
    padding: 1px 8px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: white;
}

.app .menu .option:hover {
    background-color: rgb(24, 79, 168) !important;
}

.app .menu .divider {
    margin: 0 !important;
}