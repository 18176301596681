.card-16 {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    box-shadow: 0px 0px 15px rgba(86, 86, 86, 0.1);
  }
  
  .card-16-outline {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    border: solid #dadddf;
    background-color: #fafcfd;
    border-width: 1px;
  }
  
  .card-8 {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(86, 86, 86, 0.1);
  }
  
  .card-8-outline {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: solid #dadddf;
    background-color: #fafcfd;
    border-width: 1px;
  }
  
  .card-top-8 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }