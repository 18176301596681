.nav-bar .nav-item {
  text-decoration: none;
}

.nav-bar {
  padding: 0;
}

.nav-bar .icon {
  width: 24;
  height: 24px;
  margin-right: 12px;
}

.nav-bar .nav-link {
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 12px;
  color: white;
  background-color: #475467;
  border-radius: 6px;
  transition: all 0.3s;
}

.nav-bar .nav-link.collapsed {
  background: none;
}

.nav-bar .nav-link:hover {
  color: #300c3e;
  background-color: #ff6600;
}



.menu-link {
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  padding: 8px 12px;
  color: white;
}

.menu-link .icon {
  width: 24;
  height: 24px;
  margin-right: 12px;
}

.menu-link:hover {
  color: #300c3e;
  background-color: #ff6600;
  cursor: pointer;
}