@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Mulish', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-decor { text-decoration: none; }

.login-header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(122, 81, 198);
  padding: 7px 15px;
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 280px;
  padding: 16px;
}

.side-bar {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: space-between;
  padding: 16px 16px;
  width: 280px;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #101828;
  list-style: none;
}

.card-item-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 5px;
  border-radius: 8px;
}

.button-u {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  color: #2c2c2c;
  border-radius: 3px !important;
  font-weight: 600;
  font-size: 13px !important;
  padding: 5px 10px;
  transition: all 0.3s;
}

.button-u:hover {
  background-color: coral !important;
  border: rgb(255, 108, 55) 1px solid !important;
  color: rgb(255, 255, 255)!important;
}

.table-cell {
  padding: 4px 10px !important;
  border: none;
}

.table-cell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-cell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.card-item-count {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px 20px;
  border-radius: 8px;
  border: #cfd6d9 1px solid;
}

.img-photo {
  width: 100px;
  height: 100px;
  background-color: rgb(236, 240, 244);
  border-radius: 100px;
  border: solid 1px rgb(116, 149, 174);
}

.img-photo-sm {
  width: 30px;
  height: 30px;
  background-color: rgb(236, 240, 244);
  border-radius: 100px;
  border: solid 1px rgb(116, 149, 174);
}

.img-photo-sm-x {
  width: 25px;
  height: 25px;
  background-color: rgb(236, 240, 244);
  border-radius: 100px;
  border: solid 1px rgb(116, 149, 174);
}

.img-news {
  width: 350px;
  height: 250px;
  background-color: rgb(236, 240, 244);
  border: solid 1px rgb(116, 149, 174);
}

.img-news-sm {
  width: 60px;
  height: 40px;
  background-color: rgb(236, 240, 244);
  border-radius: 3px;
  border: solid 1px rgb(116, 149, 174);
}

.upload-btn {
  border-radius: 10px;
  padding: 2px 15px;
  font-family: 'Helvetica', sans-serif;
  transition: .5s;
  border: 1px dashed #BBB;
  text-align: center;
  font-size: 13px;
  background-color: #f8f4f4;
  cursor: pointer;
}

.image-u {
  height: 250px;
  border: #afafaf 1px solid;
  object-fit:scale-down;
  transition: all 0.3s;
  cursor: pointer;
}

.image-u:hover { opacity: 0.7; }

.image-modal {
  display: none;
  position: fixed;
  z-index: 901;
  padding: 100px 0px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

.image-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

.image-modal-content {  
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform: scale(0.1)} 
  to {transform: scale(1)}
}

.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover, .close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  border:#5dbaf7 2px dashed;
  border-radius: 8px;
}

.instructions {
  background-color: rgb(41, 41, 41);
  border-radius: 8px;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
}

.hover-cursor { cursor: pointer; }

.show { display: block; }

.hide { display: none; }

.valid { color: green; }

.invalid { color: red; }